import { Fragment, useState } from "react";
import { NavLink } from '../components/NavbarElements';
import NavigationMenu from '../data/NavigationMenu';

const NavBar = (props) => {
    const navItems = NavigationMenu;
    const [menuOpenStatus, setMenuOpenStatus] = useState('collapse navbar-collapse');

    const menuCloseHandler = () => {

    };

    const menuBtnHandler = () => {
        setMenuOpenStatus((preVal) => {
            if(preVal === 'collapse navbar-collapse') {
                setMenuOpenStatus('collapse navbar-collapse in');
            } else {
                setMenuOpenStatus('collapse navbar-collapse');
            }
        })
       
    }

    return (
        <div>
            {/* <!--Navbar Begin--> */}
            <div className="row nav">
                <div className="navigation">
                    <nav className="navbar nav-color">
                        <div className="container-fluid">
                            <div className="navbar-header">
                                <div className="row">
                                    <div className="col-xs-3 menu-bar">
                                        <button onClick={menuBtnHandler} type="button" className="navbar-toggle" data-toggle="collapse"
                                            data-target="#myNavbar">
                                            <span className="icon-bar"></span>
                                            <span className="icon-bar"></span>
                                            <span className="icon-bar"></span>
                                            <div className="nav-menu">Menu</div>
                                        </button>
                                    </div>
                                    <div className="col-xs-6">
                                        <div className="logo-pannel">
                                            <a className="logo"><img width="50px"
                                                src='./media/images/dev-mobile-logo.png' /></a>
                                            <p className="logo-text">Dev Technology Solutions
                                            </p>
                                        </div>
                                    </div>
                                    <div className="col-xs-3 enq-bar">
                                        <div id="Enquiry">
                                            <div className="enq-panel">
                                                <div className="enq-img"></div>
                                                <NavLink to='/contact' className="enquiry-menu">Enquiry</NavLink>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>


                            <div className={menuOpenStatus}>
                                <ul className="nav navbar-nav" id="navigation">
                                    {
                                        navItems.map((navItem, index) => {
                                            return 'dropdown' === navItem.type ?
                                                <li key={index} className="dropdown">
                                                    <a className="dropdown-toggle" data-toggle="dropdown">{navItem.name}
                                                        <span className="caret"></span>
                                                    </a>
                                                    <ul className="dropdown-menu" key={index}>
                                                        {
                                                            navItem.course ? navItem.course.map((c, inx) => {
                                                                return <li key={inx}>
                                                                    <NavLink onClick={menuBtnHandler} to={'/' + c.url + '/' + index + '/' + inx} className="courses-list">{c.courseName}</NavLink>
                                                                </li>
                                                            }) : ''
                                                        }
                                                    </ul>
                                                </li> :
                                                <li key={index}>
                                                    <NavLink onClick={menuBtnHandler} to={navItem.url}>{navItem.name}</NavLink>
                                                </li>
                                        })
                                    }
                                </ul>
                            </div>


                        </div>
                    </nav>
                </div>
            </div>


            {/* <!--Navbar End--> */}
        </div>
    );
}

export default NavBar;
