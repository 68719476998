import { Fragment, useEffect, useRef, useState, memo } from "react";
import parse from 'html-react-parser';
import { useParams } from "react-router-dom";
import { NavLink } from '../components/NavbarElements';
import RequestForm from "../components/RequestForm";

const Course = (props) => {
    const [isActive, setIsActive] = useState(false);
    const { courseIndex, courseId } = useParams();
    const courseD = props.courseDetails[courseIndex - 1].course[courseId];
    const navigationMenu = props.navigationMenu;
    const presentage = Math.round((courseD.rating / 5 * 100) / 10) * 10 + "%";
    const courseDetailForRoute = props.courseDetailForRoute;
    const coursePage = useRef(null);

    const setIsActiveHandler = (inx) => {
        if (isActive === inx) {
            return setIsActive(null)
        }
        setIsActive(inx);
    }

    useEffect(() => {
        const y = coursePage.current.getBoundingClientRect().top + window.pageYOffset - 57;
        window.scrollTo({ top: y, behavior: 'smooth' });
    }, []);

    return <div ref={coursePage}>
        <div id="info-banner" className="row col-md-9 col-xs-12">
            <div className="row">
                <div className="col-md-8"><span id="bannerTitle" className="ba-header">{courseD['banner-title']}</span></div>
                <div className="col-md-4">
                    <div className="rate-panel">
                        <div className="col-md-12">
                            <div className="ratings">
                                Rated
                                <div className="star-outer">
                                    <div className="star-inner star-inner-mobile" style={{ width: presentage }}>
                                    </div>
                                </div>
                                <span id="rating">{courseD.rating}</span>/5
                            </div>
                        </div>
                        <div className="col-md-12"><span className="sati" id="reviews">Reviews: {courseD.reviews}</span></div>
                    </div>
                </div>
            </div>

            <div className="row">

                <div className="content">
                    {
                        courseD['banner-card'].map((banner, i) => {
                            return <div key={i} className="col-md-6">
                                <div className="card">
                                    <div className="col-md-2 col-xs-2">
                                        <div className="banner-img">
                                            <svg className="svg-inline--fa fa-users fa-w-20" aria-hidden="true" data-prefix="fas" data-icon="users" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 512" data-fa-i2svg="">
                                                <path fill="currentColor" d="M96 224c35.3 0 64-28.7 64-64s-28.7-64-64-64-64 28.7-64 64 28.7 64 64 64zm448 0c35.3 0 64-28.7 64-64s-28.7-64-64-64-64 28.7-64 64 28.7 64 64 64zm32 32h-64c-17.6 0-33.5 7.1-45.1 18.6 40.3 22.1 68.9 62 75.1 109.4h66c17.7 0 32-14.3 32-32v-32c0-35.3-28.7-64-64-64zm-256 0c61.9 0 112-50.1 112-112S381.9 32 320 32 208 82.1 208 144s50.1 112 112 112zm76.8 32h-8.3c-20.8 10-43.9 16-68.5 16s-47.6-6-68.5-16h-8.3C179.6 288 128 339.6 128 403.2V432c0 26.5 21.5 48 48 48h288c26.5 0 48-21.5 48-48v-28.8c0-63.6-51.6-115.2-115.2-115.2zm-223.7-13.4C161.5 263.1 145.6 256 128 256H64c-35.3 0-64 28.7-64 64v32c0 17.7 14.3 32 32 32h65.9c6.3-47.4 34.9-87.3 75.2-109.4z"></path>
                                            </svg>
                                        </div>
                                    </div>
                                    <div className="col-md-10 col-xs-10">
                                        <h5 id="bannerCardOne">{banner.title}</h5>
                                        <p className="card_decri" id="bannerCardSubOne">{banner.subtitle}</p>
                                    </div>
                                </div>
                            </div>
                        })
                    }
                </div>
            </div>
        </div>

        <div className="course-page row">
            <div className="col-md-8 col-sm-8 feature-course">
                <h3 className="middle-font fontsize-22 black-colo">{courseD.title}</h3>
                <div className="desc">{parse(courseD.description)}</div>
                {
                    courseD.subtitle.map((subtitle, inx) => {
                        return <Fragment key={inx}>
                            <h5 className="course-sub-title">{subtitle.name}</h5>
                            <ul className='list'>
                                {
                                    subtitle.topics.map((topic, i) => {
                                        return <li key={i}>{topic}</li>
                                    })
                                }
                            </ul>
                        </Fragment>
                    })
                }

            </div>

            <div className="col-md-4 col-sm-4 side-menu">
                <div className="row">
                    <div className="req-btn col-md-12 col-sm-6 pfull-0">
                        <div className="message-session-home">
                            <h5 className="requestForDemoClass">Request For Demo Class</h5>
                            {/* Form Begins */}
                            <RequestForm courseDetail={courseDetailForRoute} />
                            {/* Form End */}
                        </div>
                    </div>
                </div>


                <div className="row course-side-panel">
                    <div id="side-menu-list">
                        <div className="">
                            {
                                navigationMenu.map((nav, inx) => {
                                    return 'menu' === nav.type ?
                                        <button key={inx} className="side-static-menu"> {nav.name}</button> :

                                        <Fragment key={inx}>
                                            <button className={isActive === inx ? "accordion active" : "accordion"} onClick={() => setIsActiveHandler(inx)}>{nav.name}</button>
                                            {
                                                <div className={isActive === inx ? "accordion-panel show" : "accordion-panel"}>
                                                    <ul className="side-list">
                                                        {
                                                            nav.course.map((subMenu, i) => {
                                                                return <li key={i}>
                                                                    <NavLink to={'/' + subMenu.url + '/' + inx + '/' + i}>{subMenu.titleTag}</NavLink>
                                                                </li>
                                                            })
                                                        }
                                                    </ul>
                                                </div>
                                            }
                                        </Fragment>
                                })
                            }
                        </div>
                    </div>
                </div>

                <div className="row">
                    <div className="customer">
                        <h2>What our students say</h2>
                        {
                            courseD.comments.map((comment, i) => {
                                return <div className='row inner' key={i}>
                                    <div className="col-md-12"><span className="icon"><i className="fa fa-user"></i></span></div>
                                    <div className="col-md-12"><h4>{comment.name}</h4></div>
                                    <div className="col-md-12"><p>{comment.comment}</p></div>
                                </div>
                            })
                        }
                    </div>
                </div>

                <div className="row">
                    <div className="youtube_panel">
                        <iframe width="100%" height="250" src={courseD.youtube_url}> </iframe>
                    </div>
                </div>

            </div>
        </div>
    </div>
}

export default memo(Course);