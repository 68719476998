import { Fragment, useEffect, useRef } from "react"
import parse from 'html-react-parser';

const Article = (props) => {
    const articleDetails = props.articleDetails;
    const articlePage = useRef(null);

    useEffect(() => {
        const y = articlePage.current.getBoundingClientRect().top + window.pageYOffset - 57;
        window.scrollTo({ top: y, behavior: 'smooth' });
    }, []);

    return <div ref={articlePage}>
        {
            articleDetails.map((article, i) => {
                return <div key={i} className="inner-article">
                    <div className="row">
                        <div className="article-header">
                            <div className="col-md-12 col-xs-12">
                                <div className="row">
                                    <div className="col-md-6 col-xs-6">
                                        <i className="fas fa-calendar-alt"></i> <span className="date">{article.date}</span>
                                    </div>
                                    <div className="col-md-6 col-xs-6">
                                        <i className="fas fa-user"></i> <span className="user">{article.pubish_by}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div id='articleDescription'>
                        {
                            article.describe.map((descr, inx) => {
                                return <Fragment key={inx}>
                                    <div className="row">
                                        <div className="col-md-12 col-xs-12">
                                            <h4>
                                                {parse(descr.title)}
                                            </h4>
                                            <p>
                                                {parse(descr.description)}
                                            </p>
                                        </div>
                                    </div>

                                    <div className="row">
                                        <div className="coding">
                                            <div className="col-md-12">
                                                <h4>Syntax</h4>
                                                <pre>{descr.sampleCode}</pre>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="row">
                                        <div className="output">
                                            <div className="col-md-12">
                                                <h4>Output</h4>
                                                <div className="output_img">
                                                    <img src={`./media/images/${descr.outputImageURL}`} />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </Fragment>
                            })
                        }
                    </div>
                </div>
            })
        }
    </div>
}

export default Article;