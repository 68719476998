import { Fragment } from "react";

const MobileDynamicBanner = (props) => {
    return <Fragment>
        {/* <!--Mobile Dynamic Banner Begins--> */}
        <div className="row for-mobile mobile-banner">
            <div className="dynamic-banner">
                <div className="row">
                    <div className="col-md-3 col-xs-12 banner-image">
                        <img className="img-responsive" src='./media/images/dev-banner.jpg' widht="220px" height="220px" />
                    </div>
                </div>
            </div>
        </div>
        {/* <!--Mobile Dynamic Banner End--> */}
    </Fragment>
}

export default MobileDynamicBanner;