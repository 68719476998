import CourseDetails from './CourseDetails';

const NavigationMenu = [
    {
        name: 'Home',
        type: 'menu',
        url: '/'
    },{
        name: 'Programming Courses',
        type: 'dropdown',
        course: CourseDetails[0].course
    },{
        name: 'RPA-Traning',
        type: 'dropdown',
        course: CourseDetails[1].course
    },{
        name: 'Trending Courses',
        type: 'dropdown',
        course: CourseDetails[2].course
    },{
        name: 'Other Courses',
        type: 'dropdown',
        course: CourseDetails[3].course
    },{
        name: 'Articles',
        type: 'menu',
        url: '/article'
    },{
        name: 'Job Openings',
        type: 'menu',
        url: '/job-openings'
    },{
        name: 'Contact Us',
        type: 'menu',
        url: '/contact'
    }
];


export default NavigationMenu;