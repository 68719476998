import { Fragment } from "react";

const Banner = (props) => {
    return (
        <Fragment>
            {/* <!--Banner Begin--> */}
            <div className="row for-desktop">
                <div className="col-md-12">
                    <div className="banner">
                        <div className="row">
                            <img className="img-responsive" src={'./media/images/dev-banner.jpg'} />
                        </div>
                    </div>
                </div>
            </div>
            {/* <!--Banner End--> */}
        </Fragment>
    );
}

export default Banner;
