const EmailConfig = {
    "url": "https://api.emailjs.com/api/v1.0/email/send",
    "method": "POST",
    "service_id": "service_g5ojbx6", 
    "template_id": "template_ccns3mh", 
    "user_id": "GAmNZWwqGYsO52B57", 
    "template_params": { 
        "from_name": "" ,
        "course_name": "",
        "email_id": "",
        "mobile_no": ""
    } 
}

export default EmailConfig;