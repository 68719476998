import { Fragment } from "react";

const Header = (props) => {
       return (
        <Fragment>
            {/* Header Begins */}
            <div className="row header-section-1">
                <div className="head">
                    <div className="col-md-6 col-xs-6">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="dev-logo">
                                    <a href="http://devtechnos.in/" className="logo">
                                        <img alt="" src='./media/images/dev-logo-1.png'/>
                                    </a>
                                    <p className="logo-text">Dev Technology Solutions</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-6 col-xs-6">
                        <div className="right txt-center">
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="com-name">Dev Technology Solutions</div>
                                </div>
                                <div className="col-md-12">
                                    <div className="com-address">No:65, Venkatesan Street, West Tambaram, Chennai-600045.</div>
                                </div>
                                <div className="col-md-12">
                                    <div className="com-mobile">
                                        <span id="phNumber">+91-9344292441</span>
                                        <img alt="" src='./media/images/Businessman.png' width="70px" height="70px"
                                            style={{ position: "relative", left: "-160px", marginTop: "-43px", display: "inline-block" }} />
                                    </div>
                                </div>
                                <div className="col-md-12">
                                    <div className="com-email">Email: <a className="udrline"
                                        href="">devtechnologysolutions@gmail.com</a></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* Header End */}
        </Fragment>
    );
}

export default Header;
