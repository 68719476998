import { useEffect, useReducer, useState, memo, Fragment } from "react";
import EmailConfig from '../EmailConfig';
import axios from "axios";

const reducer = (state, action) => {
    switch (action.type) {
        case "NAME":
            const hasNameError = action.val.trim() === '';
            return {
                ...state,
                name: { value: action.val, hasError: hasNameError, error: hasNameError ? "Name is required" : '' },
            }
        case "COURSE":
            const hasCourseError = action.val.trim() === '';
            return {
                ...state,
                course: { value: action.val, hasError: hasCourseError, error: hasCourseError ? "Course is required" : '' }
            }
        case "EMAILID":
            const hasEmaiError = !/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(action.val);
            return {
                ...state,
                email: {
                    value: action.val,
                    hasError: hasEmaiError,
                    error: hasEmaiError ? "Invalid Email" : ''
                }
            }
        case "MOBILENO":
            const hasMobileError = /^[0-9]{10}$/.test(action.val);
            return {
                ...state,
                mobile: {
                    value: action.val,
                    hasError: !hasMobileError,
                    error: !hasMobileError ? "Invalid Mobile No" : ''
                }
            }
        case "RESET":
            return {
                name: { value: '', hasError: true, error: "" },
                course: { value: "default", hasError: true, error: "" },
                email: { value: "", hasError: true, error: "" },
                mobile: { value: "", hasError: true, error: "" },
            }
    }
};

const RequestForm = (props) => {
    const emailConfig = EmailConfig;
    const courseDetail = props.courseDetail;
    const [formIsValid, setFormIsValid] = useState(false);
    const [emailSendState, setEmailSend] = useState(null);

    const [enquiryState, dispatchEnquiry] = useReducer(reducer,
        {
            name: { value: '', hasError: true, error: "" },
            course: { value: "default", hasError: true, error: "" },
            email: { value: "", hasError: true, error: "" },
            mobile: { value: "", hasError: true, error: "" },
        }
    );

    const handleName = (event) => {
        dispatchEnquiry({ type: "NAME", val: event.target.value });
    }

    const handleCourse = (event) => {
        dispatchEnquiry({ type: "COURSE", val: event.target.value });
    };

    const handleEmail = (event) => {
        dispatchEnquiry({ type: "EMAILID", val: event.target.value });
    }

    const handleMobileNo = (event) => {
        dispatchEnquiry({ type: "MOBILENO", val: event.target.value });
    }

    const { name: isNameVaild, course: isCourseVaild, email: isEmailVaild, mobile: isMobileValid } = enquiryState;

    useEffect(() => {
        const contition = !isNameVaild.hasError && !isCourseVaild.hasError && !isEmailVaild.hasError && !isMobileValid.hasError;
        setFormIsValid(contition);
    }, [isNameVaild, isCourseVaild, isEmailVaild, isMobileValid]);

    const submitHandler = () => {
        const payload = {
            "from_name": enquiryState.name.value,
            "course_name": enquiryState.course.value,
            "email_id": enquiryState.email.value,
            "mobile_no": enquiryState.mobile.value
        };
        axios({
            url: emailConfig.url,
            method: emailConfig.method,
            data: {
                "service_id": emailConfig.service_id,
                "template_id": emailConfig.template_id,
                "user_id": emailConfig.user_id,
                "template_params": payload
            },
        }).then((resp) => {
            if (resp.status === 200) {
                setEmailSend('success');
                dispatchEnquiry({ type: "RESET" });
            }
        }).then((error) => {
            if (error) {
                setEmailSend('failure');
            }
        })
    }

    return (
        <Fragment>
            {
                emailSendState === 'success' &&
                <div className="thanksMsg displayMsg">
                    Thank you, our technical experts will call you...
                </div>
            }
            {
                emailSendState === 'failure' &&
                <div className="issueMsg displayMsg">
                    We are facing high network traffic please try after some time.
                </div>
            }
            <form className="form-horizontal" id="enquiryForm">
                <div className="form-group required">
                    <label className="control-label col-sm-4">Name:</label>
                    <div className="col-sm-8 p-0">
                        <input type="text" className="dev-form-control" placeholder="Enter your name"
                            name="name"
                            onChange={handleName}
                            value={enquiryState.name.value}
                            required />

                        <span className={enquiryState.name.hasError ? "err_message show" : ""} id="name">
                            {enquiryState.name.error}
                        </span>
                    </div>
                </div>
                <div className="form-group required">
                    <label className="control-label col-sm-4">Course:</label>
                    <div className="col-sm-8 p-0">
                        <select className="dev-form-control" name="course"
                            onChange={handleCourse}
                            value={enquiryState.course.value}
                            required>
                            <option value="default" disabled>Select Your Course</option>
                            {
                                courseDetail.map((course, i) => {
                                    return <option key={i}>{course.titleTag}</option>
                                })
                            }
                        </select>

                        <span className={enquiryState.course.hasError ? "err_message show" : ""} id="name">
                            {enquiryState.course.error}
                        </span>
                    </div>
                </div>
                <div className="form-group required">
                    <label className="control-label col-sm-4">Email ID:</label>
                    <div className="col-sm-8 p-0">
                        <input type="text" className="dev-form-control" placeholder="Enter your email id"
                            name="email"
                            onChange={handleEmail}
                            value={enquiryState.email.value}
                            required />

                        <span className={enquiryState.email.hasError ? "err_message show" : ""} id="name">
                            {enquiryState.email.error}
                        </span>
                    </div>

                </div>
                <div className="form-group required">
                    <label className="control-label col-sm-4">Mobile No:</label>
                    <div className="col-sm-8 p-0">
                        <input minLength="10" maxLength="10" type="text"
                            className="dev-form-control"
                            placeholder="Enter your mobile No"
                            name="phone"
                            onChange={handleMobileNo}
                            value={enquiryState.mobile.value}
                            required />

                        <span className="err_message" id="phone"></span>

                        <span className={enquiryState.mobile.hasError ? "err_message show" : ""} id="name">
                            {enquiryState.mobile.error}
                        </span>
                    </div>
                </div>
                <div className="form-group required">
                    <div className="submitBtn">
                        <button onClick={submitHandler} disabled={!formIsValid} type="button" id="sendEmail" className="btn btn-primary">Submit</button>
                    </div>
                </div>
            </form>
        </Fragment>
    )
}

export default memo(RequestForm);