import parse from 'html-react-parser';
import { useEffect, useRef, useState } from 'react';

const JobOpenings = (props) => {
    const jobDetails = props.jobDetails;
    const [moreDetails, setMoreDetails] = useState(false);
    const jopOpeningPage = useRef(null);

    const showMoreHandler = (index) => {
        if (moreDetails === index) {
            return setMoreDetails(null)
        }
        setMoreDetails(index);
    }

    useEffect(() => {
        const y = jopOpeningPage.current.getBoundingClientRect().top + window.pageYOffset - 57;
        window.scrollTo({top: y, behavior: 'smooth'});
    },[]);

    return <div ref={jopOpeningPage} id="jobPage" className="jobs row">
        {
            jobDetails.map((job, i) => {
                return <div className="section" key={i}>
                    <div className="col-md-1 col-sm-1">
                        <div className="image_panel">
                            <img src={`./media/images/${job.image}`} width="75px" height="75px" />
                        </div>
                    </div>
                    <div className="col-md-11 col-sm-11">
                        <div className="content">
                            <h4><font color="red">{job.title}</font></h4>
                            <h5><b><u>Company Profile:</u></b></h5>
                            <p>{job.about_company}</p>

                            <div className={moreDetails === i ? "jobs-show" : "jobs-hide"}>
                                <p>The requirement for which we would be considering your profile is: </p>
                                <div className="skills">
                                    <p><u><b>Job Title:</b></u> {job.JobTitle}</p>
                                    <p><u><b>Experience Level:</b></u> {job.ExperienceLevel}</p>

                                    <p><u><b>WorkLocation:</b></u> {job.WorkLocation}</p>

                                    <p><u><b>Job Description:</b></u> {parse(job.job_description)}</p>
                                </div>
                                <div className="send_resume">
                                    <p>Your resume will be awaited. Please send across the same to <font color="red"><b>{job.Email}</b></font></p>
                                </div>
                            </div>

                            <p><button className="show_more" key="0" id="show_${i}" onClick={() => showMoreHandler(i)}>Show more...</button></p>
                        </div>
                    </div>
                </div>
            })
        }
    </div>
}

export default JobOpenings;