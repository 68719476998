const ArticleDetails = [
    {
        "url": "fresher-technical-interview-question1",
        "title": "fresher-technical-interview-question1",
        "date": "Feb.05.2020",
        "pubish_by": "Kumar",
        "image": "article-interview-questions-java.png",
        "describe": [{
            "title": "Fresher Technical Interview Question1",
            "description": "<b>What is an Adam number?</b></br></br>It’s the reverse square root of the reverse of the square of a number is the number itself called as Adam Number.</br></br><b>Note:Take 12</b><br>square of 12 = 144<br>reverse of a square of 12 = 441 <br>square roots of the reverse of a square about 12 = 21 <br>The reverse square root of the reverse of a square of 12 = 12, then number itself.<br>Such number is called Adam Number.",
            "sampleCode": "AdamsNumber.java\r\n\r\npublic class AdamsNumber {\r\n\r\n\tpublic static void main(String[] args) {\r\n\t\tAdamsNumber an = new AdamsNumber();\r\n\t\tint i, n, rn;\r\n\t\tint sn, rsn, rrsn;\r\n\t\tSystem.out.println(\"List of Adam Numbers under 1000\");\r\n\t\tfor (i = 10; i < 1000; i++) {\r\n\t\t\tn = i;\r\n\t\t\trn = an.ReverseNumber(i);\r\n\t\t\tif (n == rn) continue;\r\n\t\t\tsn = n * n;\r\n\t\t\trsn = rn * rn;\r\n\t\t\trrsn = an.ReverseNumber(rsn);\r\n\t\t\tif (rrsn == sn) {\r\n\t\t\t\tSystem.out.println(n);\r\n\t\t\t}\r\n\t\t}\r\n\t}\r\n\tint CountNumberOfDigits(int n) {\r\n\t\tint numdgits = 0;\r\n\t\tdo {\r\n\t\t\tn = n / 10;\r\n\t\t\tnumdgits++;\r\n\t\t}\r\n\t\twhile (n > 0);\r\n\t\treturn numdgits;\r\n\t}\r\n\tint ReverseNumber(int n) {\r\n\t\tint i = 0, result = 0;\r\n\t\tint numdigits = CountNumberOfDigits(n);\r\n\t\tfor (i = 0; i < numdigits; i++) {\r\n\t\t\tresult *= 10;\r\n\t\t\tresult += n % 10;\r\n\t\t\tn = n / 10;\r\n\t\t}\r\n\t\treturn result;\r\n\t}\r\n}",
            "outputImageURL": "adamnumder.png"
        },
        {
            "title": "<b>Factorial Number</b>",
            "description": "<b>What is Factorial Number?</b></br></br>The factorial function (symbol: !) means to multiply a series of descending natural numbers.</br></br><b>Note:4! = 4 × 3 × 2 × 1 = 24</b><br>7! = 7 × 6 × 5 × 4 × 3 × 2 × 1 = 5040",
            "sampleCode": "FactorialNumber.java\r\npublic class FactorialNumber {\r\n\r\n\tpublic static void main(String[] args) {\r\n\t\tint n = 4;\r\n\t\tint result = 1;\r\n\t\tfor (int i = 1; i <= n; i++) {\r\n\t\t\tresult = result * i;\r\n\t\t}\r\n\t\tSystem.out.println(\"The factorial of 4 is \" + result);\r\n\t}\r\n}",
            "outputImageURL": "factorialnumber.png"
        },
        {
            "title": "<b>Armstrong Number</b>",
            "description": "<b>What is armstrong number?</b></br></br>An armstrong number is any number of n digits which is equal to the sum of nth power of digits in the number.</br></br><b>Example:</b><br>If 'abc' is a three digit Armstrong number, then (a^3) + (b^3) +(c^3) =abc.",
            "sampleCode": "ArmstrongNumber.java\r\n\r\npublic class ArmstrongNumber {\r\n\r\n\tpublic static void main(String[] args) {\r\n\t\tfor (int num = 100; num <= 1000; num++) {\r\n\t\t\tint sum = 0, r = 0, temp = num;\r\n\r\n\t\t\twhile (temp > 0) {\r\n\t\t\t\tr = temp % 10;\r\n\t\t\t\tsum = sum + (r * r * r);\r\n\t\t\t\ttemp = temp / 10;\r\n\t\t\t}\r\n\r\n\t\t\tif (num == sum) \r\n\t\t\t System.out.println(\"Number \" + num + \" is an armstrong number\");\r\n\t\t}\r\n\t}\r\n}",
            "outputImageURL": "armstrongnumber.png"
        },
        {
            "title": "<b>Flipped Triangle</b>",
            "description": "<b>What is Congruent Triangles or Flipped Triangle?</b></br></br>Triangles are congruent when they have exactly the same three sides and exactly the same three angles.</br></br>",
            "sampleCode": "FlippedTriangle.java\r\n\r\npublic class FlippedTriangle {\r\n\r\n\tpublic static void main(String[] args) {\r\n\t\tint rows = 4;\r\n\t\tint cntr = 1;\r\n\t\tint start;\r\n\t\tint val;\r\n\t\tfor (int i = 1; i <= rows; i++) {\r\n\r\n\t\t\tfor (int k = rows - i; k >= 1; k--) {\r\n\t\t\t\tSystem.out.print(\" \");\r\n\t\t\t}\r\n\t\t\tstart = cntr + i - 1;\r\n\t\t\tval = start;\r\n\t\t\tfor (int j = 1; j <= i; j++) {\r\n\r\n\t\t\t\tSystem.out.print(start);\r\n\t\t\t\tstart--;\r\n\t\t\t\tcntr++;\r\n\t\t\t}\r\n\t\t\tSystem.out.println();\r\n\t\t}\r\n\t}\r\n}",
            "outputImageURL": "flippedtriangle.png"
        }
        ]
    },
    {
        "url": "fresher-technical-interview-question2",
        "title": "fresher-technical-interview-question2",
        "date": "Feb.20.2020",
        "pubish_by": "Kumar",
        "image": "article-interview-questions-java.png",
        "describe": [{
            "title": "Fresher Technical Interview Question2",
            "description": "<b>What is Prime Number?</b></br></br>A prime number is a whole number greater than 1, whose only two whole-number factors are 1 and itself.",
            "sampleCode": "PrimeNumberDemo.java\r\n\r\nimport java.util.Scanner;\r\n\r\npublic class PrimeNumberDemo {\r\n\r\n\tpublic static void main(String[] args) throws Exception {\r\n\r\n\t\tint i;\r\n\t\tScanner input = new Scanner(System.in);\r\n\t\tSystem.out.println(\"Enter number:\");\r\n\t\tint num = Integer.parseInt(input.nextLine());\r\n\t\tSystem.out.println(\"Prime number are: \");\r\n\r\n\t\tfor (i = 1; i < num; i++) {\r\n\t\t\tint j;\r\n\t\t\tfor (j = 2; j < i; j++) {\r\n\t\t\t\tint n = i % j;\r\n\t\t\t\tif (n == 0) {\r\n\t\t\t\t\tbreak;\r\n\t\t\t\t}\r\n\t\t\t}\r\n\t\t\tif (i == j) {\r\n\t\t\t\tSystem.out.print(\" \" + i);\r\n\t\t\t}\r\n\t\t}\r\n\t}\r\n}",
            "outputImageURL": "primenumber.png"
        },
        {
            "title": "What is Fibonacci series?",
            "description": "The nextnumber is found by adding up the two numbers before it. The 2 is found by adding the two numbersbefore it (1+1).",
            "sampleCode": "FibonacciSeriesDemo.java\r\n\r\npublic class FibonacciSeriesDemo {\r\n\r\n\tpublic static void main(String[] args) {\r\n\r\n\t\tint first = 0, second = 1;\r\n\t\tint next = 1;\r\n\t\tSystem.out.print(first + \",\" + second);\r\n\t\tint maxval = 50;\r\n\r\n\t\twhile (first + second <= maxval) {\r\n\t\t\tnext = first + second;\r\n\t\t\tSystem.out.print(\", \" + next);\r\n\t\t\tfirst = second;\r\n\t\t\tsecond = next;\r\n\t\t}\r\n\t}\r\n}",
            "outputImageURL": "fibonacciseries.png"
        },
        {
            "title": "FloydsTriangle",
            "description": "This program will demonstrate the FloydsTriangle using java program.",
            "sampleCode": "FloydsTriangleDemo.java\r\n\r\npublic class FloydsTriangleDemo {\r\n\r\n\tpublic static void main(String args[]) {\r\n\t\r\n\t\tint i, j, n;\r\n\t\tfor(i = 1; i <= 5; i++) {\r\n\t\t\tfor(j = i, n = 1; n <= i; n++, j++) {\r\n\t\t\t\tSystem.out.print(j % 2 + \" \");\r\n\t\t\t}\r\n\t\t\tSystem.out.println(\" \");\r\n\t\t}\r\n\t}\r\n}",
            "outputImageURL": "floydstriangle.png"
        },
        {
            "title": "NumberReverse",
            "description": "This program will demonstrate the NumberReverse program using java.",
            "sampleCode": "NumberReverseDemo.java\r\n\r\npublic class NumberReverseDemo {\r\n\r\n\tpublic static void main(String[] args) {\r\n\r\n\t\tint original = 12345;\r\n\t\tStringBuffer reverse = new StringBuffer();\r\n\t\tString str = Integer.toString(original);\r\n\t\tint length = str.length();\r\n\r\n\t\tfor (int i = length - 1; i >= 0; i--) {\r\n\t\t\treverse = reverse.append(str.charAt(i));\r\n\t\t}\r\n\r\n\t\tSystem.out.println(\"Reverse of the number \" + reverse.toString());\r\n\t}\r\n}",
            "outputImageURL": "numberreverse.png"
        },
        {
            "title": "DivideWithOutOperator",
            "description": "This program will demonstrate the DivideWithOutOperator program using java.",
            "sampleCode": "DivideWithOutOperatorDemo.java\r\n\r\npublic class DivideWithOutOperatorDemo {\r\n\r\n\tpublic static void main(String[] args) {\r\n\r\n\t\tint number = 25;\r\n\t\tint divisor = 5;\r\n\t\tint result = 0;\r\n\r\n\t\twhile ((number - divisor) >= 0) {\r\n\t\t\tresult++;\r\n\t\t\tnumber = number - divisor;\r\n\t\t}\r\n\r\n\t\tSystem.out.println(\"The Result is \" + result);\r\n\t}\r\n}",
            "outputImageURL": "dividewithoutoperator.png"
        },
        {
            "title": "ArrayAverage",
            "description": "This program will demonstrate the ArrayAverage program using java.",
            "sampleCode": "ArrayAverageDemo.java\r\n\r\npublic class ArrayAverageDemo {\r\n\r\n\tpublic static void main(String[] args) {\r\n\r\n\t\tint[] numbers = new int[] { 10, 20, 15, 25, 16, 60, 100, 5, 7 };\r\n\t\tint sum = 0;\r\n\r\n\t\tfor (int i = 0; i < numbers.length; i++)\r\n\t\t\tsum = sum + numbers[i];\r\n\r\n\t\tdouble average = sum / numbers.length;\r\n\r\n\t\tSystem.out.println(\"Average value of array elements is : \" + average);\r\n\r\n\t}\r\n}",
            "outputImageURL": "arrayaverage.png"
        },
        {
            "title": "HighestNumberInArray",
            "description": "This program will demonstrate the HighestNumberInArray using java program.",
            "sampleCode": "HighestNumberInArrayDemo.java\r\n\r\npublic class HighestNumberInArrayDemo {\r\n\r\n\tpublic static void main(String[] args) {\r\n\r\n\t\tint arr[] = { 3, 5, 8, 9, 7, 2 };\r\n\r\n\t\tint highest = arr[0];\r\n\t\tint index = 0;\r\n\r\n\t\tfor (int i = 1; i < arr.length; i++) {\r\n\t\t\tif (arr[i] > highest) {\r\n\t\t\t\thighest = arr[i];\r\n\t\t\t\tindex = i;\r\n\t\t\t}\r\n\t\t}\r\n\r\n\t\tSystem.out.println(\"Highest Number:\" + highest);\r\n\t\tSystem.out.println(\"at the index \" + index);\r\n\t}\r\n}",
            "outputImageURL": "highestnumberInarray.png"
        },
        {
            "title": "IntegerSorting",
            "description": "This program will demonstrate the IntegerSorting using java program.",
            "sampleCode": "IntegerSortingDemo.java\r\n\r\npublic class IntegerSortingDemo {\r\n\r\n\tpublic static void main(String[] args) {\r\n\r\n\t\tint temp;\r\n\t\tint num[] = { 5, 8, 2, 1, 9 };\r\n\r\n\t\tfor (int i = 0; i < num.length; i++) {\r\n\t\t\tfor (int j = i + 1; j < num.length; j++) {\r\n\t\t\t\tif (num[i] < num[j]) {\r\n\t\t\t\t\ttemp = num[i];\r\n\t\t\t\t\tnum[i] = num[j];\r\n\t\t\t\t\tnum[j] = temp;\r\n\t\t\t\t}\r\n\t\t\t}\r\n\t\t}\r\n\t\t\r\n\t\tfor (int k = 0; k < num.length; k++) {\r\n\t\t\tSystem.out.println(num[k]);\r\n\t\t}\r\n\t}\r\n}",
            "outputImageURL": "integersorting.png"
        }
        ]
    },
    {
        "url": "fresher-technical-interview-question3",
        "title": "fresher-technical-interview-question3",
        "date": "Feb.28.2020",
        "pubish_by": "Kumar",
        "image": "article-interview-questions-java.png",
        "describe": [{
            "title": "Fresher-Technical-Interview-Question3",
            "description": "<b>LowestNumberInArray</b></br></br>This program will demonstrate the LowestNumberInArray using java program.",
            "sampleCode": "LowestNumberInArrayDemo.java\r\n\r\npublic class LowestNumberInArrayDemo {\r\n\r\n\tpublic static void main(String[] args) {\r\n\r\n\t\tint arr[] = { 3, 5, 8, 1, 7, 2 };\r\n\t\tint lowest = arr[0];\r\n\t\tint index = 0;\r\n\t\t\r\n\t\tfor (int i = 1; i < arr.length; i++) {\r\n\t\t\tif (arr[i] < lowest) {\r\n\t\t\t\tlowest = arr[i];\r\n\t\t\t\tindex = i;\r\n\t\t\t}\r\n\t\t}\r\n\t\t\r\n\t\tSystem.out.println(\"Lowest Number:\" + lowest);\r\n\t\tSystem.out.println(\"at the index :\" + index);\r\n\t}\r\n}",
            "outputImageURL": "lowestnumberinarraydemo.png"
        },
        {
            "title": "Matrix",
            "description": "This program will demonstrate the Matrix program using java.",
            "sampleCode": "MatrixDemo.java\r\n\r\npublic class MatrixDemo {\r\n\r\n\tpublic static void main(String[] args) {\r\n\r\n\t\tint arr[][] = new int[3][3];\r\n\t\tint val = 10;\r\n\r\n\t\tfor (int i = 0; i < 3; i++) {\r\n\t\t\tfor (int j = 0; j < 3; j++) {\r\n\t\t\t\tarr[i][j] = val;\r\n\t\t\t\tval += 10;\r\n\t\t\t}\r\n\t\t}\r\n\r\n\t\tfor (int i = 0; i < 3; i++) {\r\n\t\t\tfor (int j = 0; j < 3; j++) {\r\n\t\t\t\tSystem.out.print(arr[i][j] + \" \");\r\n\t\t\t}\r\n\t\t\t\r\n\t\t\tSystem.out.println();\r\n\t\t}\r\n\t}\r\n}",
            "outputImageURL": "matrixdemo.png"
        },
        {
            "title": "MultiplyWithOutOperator",
            "description": "This program will demonstrate the MultiplyWithOutOperator using java program.",
            "sampleCode": "MultiplyWithOutOperatorDemo.java\r\n\r\npublic class MultiplyWithOutOperatorDemo {\r\n\r\n\tpublic static void main(String[] args) {\r\n\t\t\r\n\t\tint number1 = 10;\r\n\t\tint number2 = 5;\r\n\t\tint result = 0;\r\n\r\n\t\tfor (int i = 1; i <= number2; i++) {\r\n\t\t\tresult = result + number1;\r\n\t\t}\r\n\t\t\r\n\t\tSystem.out.println(\"The Result is \" + result);\r\n\t}\r\n}",
            "outputImageURL": "multiplywithoutoperatordemo.png"
        },
        {
            "title": "PrintA2Z",
            "description": "This program will demonstrate the PrintA2Z using java program.",
            "sampleCode": "PrintA2ZDemo.java\r\n\r\npublic class PrintA2ZDemo {\r\n\r\n\tpublic static void main(String[] args) {\r\n\t\t\r\n\t\tfor (char ch = 'a'; ch <= 'z'; ch++) {\r\n\t\t\tSystem.out.print(ch + \" \");\r\n\t\t}\r\n\t\t\r\n\t\tSystem.out.println();\r\n\t\t\r\n\t\tfor (char ch = 'A'; ch <= 'Z'; ch++) {\r\n\t\t\tSystem.out.print(ch + \" \");\r\n\t\t}\r\n\t}\r\n}",
            "outputImageURL": "printA2Zdemo.png"
        },
        {
            "title": "SortingNumberAndDigits",
            "description": "This program will demonstrate the SortingNumberAndDigits using java program.",
            "sampleCode": "SortingNumberAndDigitsDemo.java\r\n\r\npublic class SortingNumberAndDigitsDemo {\r\n\r\n\tpublic static void main(String[] args) {\r\n\t\t\r\n\t\tString str = \"abcd123efgh456\";\r\n\t\tchar[] charArray = str.toCharArray();\r\n\t\t\r\n\t\tStringBuffer str1 = new StringBuffer();\r\n\t\tStringBuffer str2 = new StringBuffer();\r\n\t\t\r\n\t\tfor (char ch: charArray) {\r\n\t\t\tif (Character.isDigit(ch)) {\r\n\t\t\t\tstr1 = str1.append(ch);\r\n\t\t\t} else {\r\n\t\t\t\tstr2 = str2.append(ch);\r\n\t\t\t}\r\n\t\t}\r\n\t\t\r\n\t\tSystem.out.println(str1);\r\n\t\tSystem.out.println(str2);\r\n\t}\r\n}",
            "outputImageURL": "sortingnumberandDigits.png"
        },
        {
            "title": "StringIntegerSwap",
            "description": "This program will demonstrate the StringIntegerSwap using java program.",
            "sampleCode": "StringIntegerSwapDemo.java\r\n\r\npublic class StringIntegerSwapDemo {\r\n\r\n\tpublic static void main(String[] args) {\r\n\t\t\r\n\t\tString s1 = \"5\";\r\n\t\tString s2 = \"10\";\r\n\r\n\t\tSystem.out.println(\"The s1 is \" + s1);\r\n\t\tSystem.out.println(\"The s2 is \" + s2);\r\n\r\n\t\ts2 = Integer.toString(Integer.parseInt(s1) + Integer.parseInt(s2));\r\n\t\ts1 = Integer.toString(Integer.parseInt(s2) - Integer.parseInt(s1));\r\n\t\ts2 = Integer.toString(Integer.parseInt(s2) - Integer.parseInt(s1));\r\n\r\n\t\tSystem.out.println(\"The s1 is \" + s1);\r\n\t\tSystem.out.println(\"The s2 is \" + s2);\r\n\t}\r\n}",
            "outputImageURL": "stringintegerswap.png"
        },
        {
            "title": "StringReverse3Ways",
            "description": "This program will demonstrate the StringReverse3Ways using java program.",
            "sampleCode": "StringReverse3WaysDemo.java\r\n\r\npublic class StringReverse3WaysDemo {\r\n\r\n\tpublic static void main(String args[]) {\r\n\t\t\r\n\t\t// original string\r\n\t\tString str = \"www.devtechnos.in\";\r\n\r\n\t\t// 1st way-reversing the string using Stringbuffer\r\n\t\tString revStr = new StringBuffer(str).reverse().toString();\r\n\t\tSystem.out.println(\"Reversed String using StringBuffer: \" + revStr);\r\n\r\n\t\t// 2nd way-reversing the string iterative method to reverse String\r\n\t\trevStr = reverse(str);\r\n\t\tSystem.out.println(\"Reverse String in Java using Iteration: \" + revStr);\r\n\r\n\t\t// 3rd way-reversing recursive method to reverse String\r\n\t\trevStr = strReverseRecurion(str);\r\n\t\tSystem.out.println(\"Reverse String in Java using Recursion: \" + revStr);\r\n\t}\r\n\r\n\r\n\tpublic static String reverse(String str) {\r\n\t\tStringBuilder strBuilder = new StringBuilder();\r\n\t\tchar[] charArr = str.toCharArray();\r\n\r\n\t\tfor (int i = charArr.length - 1; i >= 0; i--) {\r\n\t\t\tstrBuilder.append(charArr[i]);\r\n\t\t}\r\n\t\treturn strBuilder.toString();\r\n\t}\r\n\r\n\r\n\tpublic static String strReverseRecurion(String str) {\r\n\t\tif (str.length() < 2) {\r\n\t\t\treturn str;\r\n\t\t}\r\n\t\treturn strReverseRecurion(str.substring(1)) + str.charAt(0);\r\n\t}\r\n}",
            "outputImageURL": "stringreverse3ways.png"
        }
        ]
    },
    {
        "url": "object-oriented-programming-system-concepts",
        "title": "object-oriented-programming-system-concepts",
        "date": "Mar.09.2020",
        "pubish_by": "Arun",
        "image": "article-java.png",
        "describe": [{
            "title": "Object-Oriented-Programming-System-Concepts",
            "description": "Understand the Basic Object Oriented Programming System (OOP’s) Concepts.</br></br><b><u>Polymorphism:</u></b><br><li>Compile time polymorphism (ex: Method Overloading)</li><br><li> Runtime polymorphism (ex: Method Overriding)</li><br><b><u>Method Overloading:</u></b></br></br>Method Overloading is used to define <b>two or more methods of same name in a class,</b> provided that there <b>argument list or parameters are different.</b></br></br><b><u>Rules for Method Overloading:</u></b></br></br><li>All overloaded methods should be single class.</li><br><li>All overloaded methods names should be same.</li><br><li>All overloaded methods parameter should be different.</li>",
            "sampleCode": "MethodOverLoadDemo.java\r\n\r\nclass MyClass {\r\n\tvoid add(int a, int b) {\r\n\t\tSystem.out.println(\"The add (int a,int b) Result is \" + (a + b));\r\n\t}\r\n\r\n\tvoid add(int a, int b, int c) {\r\n\t\tSystem.out.println(\"The add (int a,int b,int c) Result is \" + (a + b + c));\r\n\t}\r\n}\r\n\r\nclass MethodOverLoadDemo {\r\n\tpublic static void main(String args[]) {\r\n\t\tMyClass x = new MyClass();\r\n\t\tx.add(2, 3);\r\n\t\tx.add(4, 5, 3);\r\n\t}\r\n}",
            "outputImageURL": "methodoverload.png"
        },
        {
            "title": "Method Overriding:",
            "description": "Method Overriding is used to define <b>two or more methods of same name in different class,</b> provided that there <b>argument list or parameters are same.</b></br></br><b><u>Rules for Method Overrideing:</u></b><br><li>Override methods should be in different class.</li><br><li>Override methods names should be same.</li><br><li>Override methods parameter should be same.</li>",
            "sampleCode": "MethodOverrideDemo.java\r\n\r\nclass MyClass1 {\r\n\tvoid m1() {\r\n\t\tSystem.out.println(\"This is MyClass1 m1()\");\r\n\t}\r\n}\r\n\r\nclass MyClass2 {\r\n\tvoid m1() {\r\n\t\tSystem.out.println(\"This is MyClass2 m1()\");\r\n\t}\r\n}\r\n\r\nclass MethodOverrideDemo {\r\n\tpublic static void main(String args[]) {\r\n\t\tMyClass2 x = new MyClass2();\r\n\t\tx.m1();\r\n\t}\r\n}",
            "outputImageURL": "methodoverride.png"
        },
        {
            "title": "First Scenario for Overriding:",
            "description": "Parent class methos is private and child class methos is public.",
            "sampleCode": "class MyClass1 {\r\n\tprivate void m1() {\r\n\t\tSystem.out.println(\"This is MyClass1 m1()\");\r\n\t}\r\n}\r\n\r\nclass MyClass2 {\r\n\tpublic void m1() {\r\n\t\tSystem.out.println(\"This is MyClass2 m1()\");\r\n\t}\r\n}\r\n\r\n\r\nNote : The above scenario is working.\r\n\r\n\r\nOutput:\r\n-------\r\nThis is MyClass2 m1()",
            "outputImageURL": ""
        },
        {

            "title": "Second Scenario for Overriding:",
            "description": "Parent class methos is public and child class methos is private.",
            "sampleCode": "class MyClass1 {\r\n\tpublic void m1() {\r\n\t\tSystem.out.println(\"This is MyClass1 m1()\");\r\n\t}\r\n}\r\n\r\nclass MyClass2 {\r\n\tprivate void m1() {\r\n\t\tSystem.out.println(\"This is MyClass2 m1()\");\r\n\t}\r\n}\r\n\r\nNote : The above scenario is not working.\r\n\r\n\r\nOutput:\r\n-------\r\nException in thread \"main\" java.lang.Error: Unresolved compilation problem: \r\n\tThe method m1() from the type MyClass2 is not visible",
            "outputImageURL": ""
        },
        {

            "title": "Third Scenario for Overriding:",
            "description": "Parent class and Child class methods return data type is different.",
            "sampleCode": "class MyClass1 {\r\n\tint m1() {\r\n\t\tSystem.out.println(\"This is MyClass1 m1()\");\r\n\t\treturn 0;\r\n\t}\r\n}\r\n\r\nclass MyClass2 {\r\n\tvoid m1() {\r\n\t\tSystem.out.println(\"This is MyClass2 m1()\");\r\n\t}\r\n}\r\n\r\nNote : The above scenario is working.\r\n\r\n\r\nOutput:\r\n-------\r\nThis is MyClass2 m1()",
            "outputImageURL": ""
        },
        {

            "title": "Inheritance:",
            "description": "Inheritance describes the <b>parent child relationship between two classes</b>. Which is mainly used for <b>code reusability.</b>Java supports multiple inheritance <b>(multiple parents, single child)</b> only through interfaces.Inheritance is achieved using <b>extends</b> keywork.</br></br><b><u>Types of Inheritace:</u></b><br><li>Single inheritance</li><br><li>Multilevel inheritance</li><br><li>Multiple inheritance</li><br><li>Hybrid inheritance</li><br><li>Hierarchical inheritance</li><br><b><u>Note:</u></b></br></br>We can <b>invoke a parent & child class methods using Child class object</b> (Here, <b>avoid the multiple object creation</b>).<br>Now the m1() we can use parent & child classes (Here, <b>achieving reusability</b>).<br>",
            "sampleCode": "InheritaceDemo.java\r\n\r\nclass Parent {\r\n\tvoid m1() {\r\n\t\tSystem.out.println(\"This is m1()\");\r\n\t}\r\n}\r\n\r\nclass Child extends Parent {\r\n\tvoid m2() {\r\n\t\tSystem.out.println(\"This is m2()\");\r\n\t}\r\n}\r\n\r\nclass InheritaceDemo {\r\n\tpublic static void main(String args[]) {\r\n\t\tChild x = new Child();\r\n\t\tx.m1();\r\n\t\tx.m2();\r\n\t}\r\n }",
            "outputImageURL": "inheritace.png"
        },
        {

            "title": "Encapsulation:",
            "description": "Encapsulation is the process of <b>wrapping up of data and code together into a single class.</b>Encapsulation enables <b>data hiding, hiding irrelevant information from the users of a class</b> and exposing only the relevant details required by the user.Encapsulation provides the <b>security that keeps data</b> and methods safe from inadvertent changes.</br></br><b><u>Note:</u></b><br><li>The above class CustomerCount will not able to get the <b>NoOfCustomers directly.</b></li><li>Setting and getting the value of the field NoOfCustomers is done with the <b>help of Getter and setter method</b>.</li><br>",
            "sampleCode": "EncapsulationDemo.java\r\n\r\nclass CustomerCount {\r\n\tprivate int NoOfCustomers = 0;\r\n\r\n\tpublic void setNoOfCustomers(int count) {\r\n\t\tNoOfCustomers = count;\r\n\t}\r\n\r\n\tpublic double getNoOfCustomers() {\r\n\t\treturn NoOfCustomers;\r\n\t}\r\n}\r\n\r\nclass EncapsulationDemo {\r\n\tpublic static void main(String args[]) {\r\n\t\tSystem.out.println(\"Starting CustomerCount...\");\r\n\t\tCustomerCount CustomerCount = new CustomerCount();\r\n\t\tCustomerCount.setNoOfCustomers(120);\r\n\t\tSystem.out.println(\"NoOfCustomers = \" + CustomerCount.getNoOfCustomers());\r\n\t}\r\n}",
            "outputImageURL": "encapsulation.png"
        },
        {

            "title": "Abstraction:",
            "description": "Abstraction is the process of <b>hiding the implementation details from the user</b>, only the functionality will be provided to the user.Abstraction is achieved using <b>Abstract classes, and Interfaces</b>.<br>",
            "sampleCode": "AbstractionDemo.java\r\n\r\nabstract class VehicleAbstract {\r\n\tpublic abstract void start();\r\n\r\n\tpublic void stop() {\r\n\t\tSystem.out.println(\"Stopping Vehicle in abstract class\");\r\n\t}\r\n}\r\n\r\nclass TwoWheeler extends VehicleAbstract {\r\n\tpublic void start() {\r\n\t\tSystem.out.println(\"Starting Two Wheeler\");\r\n\t}\r\n}\r\n\r\nclass FourWheeler extends VehicleAbstract {\r\n\tpublic void start() {\r\n\t\tSystem.out.println(\"Starting Four Wheeler\");\r\n\t}\r\n}\r\n\r\npublic class AbstractionDemo {\r\n\tpublic static void main(String[] args) {\r\n\t\tVehicleAbstract my2Wheeler = new TwoWheeler();\r\n\t\tVehicleAbstract my4Wheeler = new FourWheeler();\r\n\t\tmy2Wheeler.start();\r\n\t\tmy2Wheeler.stop();\r\n\t\tmy4Wheeler.start();\r\n\t\tmy4Wheeler.stop();\r\n\t}\r\n}",
            "outputImageURL": "abstraction.png"
        }
        ]
    },
    {
        "url": "understand-the-oracle-DML-DDL-DCL-TCL",
        "title": "understand-the-oracle-DML-DDL-DCL-TCL",
        "date": "Mar.14.2020",
        "pubish_by": "John",
        "image": "article-oracle.png",
        "describe": [{
            "title": "Understand the Oracle DML, DDL, DCL, TCL.",
            "description": "<b>What is DDL?</b></br></br><b>Data Definition Language (DDL)</b> statements are used to define the database structure or schema and how the data should reside in the database.<br><li><b>CREATE</b> - to create objects like (table, index, views, store procedure, function and triggers) in the database.</li></br><li><b>ALTER</b> - alters the structure of the existing database objects.</li></br><li><b>DROP</b> - delete objects from the database.</li></br><li><b>TRUNCATE</b> - remove all records from a table, including all spaces allocated for removed records.</li></br><li><b>RENAME</b> - rename an object.</li></br>",
            "sampleCode": "Create the students table along with two column using below query.\r\n\r\nCREATE TABLE STUDENTS (SID NUMBER, SNAME VARCHAR2(20));\r\n\r\n\r\nAdd the address column in students table using below query.\r\n\r\nALTER TABLE STUDENTS ADD ADDRESS VARCHAR (20);\r\n\r\n\r\nModify the address column in students table using below query.\r\n\r\nALTER TABLE STUDENTS MODIFY ADDRESS VARCHAR (40);\r\n\r\n\r\nRename the address column in students table using below query.\r\n\r\nALTER TABLE STUDENTS RENAME COLUMN ADDRESS TO SADDRESS;\r\n\r\n\r\nDrop an address column in students table using below query.\r\n\r\nALTER TABLE STUDENTS DROP COLUMN SADDRESS;\r\n\r\n\r\nDelete all rows of data in students table using below query.\r\n\r\nTRUNCATE TABLE STUDENTS;\r\n\r\n\r\nRename the table name using below query.\r\n\r\nRENAME STUDENTS TO S;\r\n\r\n\r\nDelete the table using below query.\r\n\r\nDROP TABLE S;",
            "outputImageURL": ""
        },
        {
            "title": "What is DML?",
            "description": "<b>Data Manipulation Language (DML)</b> statements are used for managing data within schema objects and it is used to <b>store, modify, retrieve, delete</b> and <b>update</b> data in database.<br><li><b>SELECT</b> - retrieve data from the a database.</li></br><li><b>INSERT</b> - insert data into a table.</li></br><li><b>UPDATE</b> - updates existing data within a table.</li></br><li><b>DELETE</b> - Delete all records from a database table.</li></br>",
            "sampleCode": "Fetch all the records from students table using below query.\r\n\r\nSELECT * FROM STUDENTS;\r\n\r\n\r\nFetch the student name alone using below query.\r\n\r\nSELECT SNAME FROM STUDENTS;\r\n\r\n\r\nInsert all the column of data into students table using below query.\r\n\r\nINSERT INTO STUDENTS VALUES (60,'BABU');\r\n\r\n\r\nInsert a student name alone into students table using below query.\r\n\r\nINSERT INTO STUDENTS (SNAME) VALUES ('KUMAR');\r\n\r\n\r\nModify the student name data, based on student id into students table using below query.\r\n\r\nUPDATE STUDENTS SET SNAME='RAJ' WHERE SID=60;\r\n\r\n\r\nDelete the students record, based on students id into students table using below query.\r\n\r\nDELETE FROM STUDENTS WHERE SID=60;",
            "outputImageURL": ""
        },
        {
            "title": "What is DCL?",
            "description": "<b>Data Control Language (DCL)</b> statements are used to give <b>rights, permissions</b> and other controls of the database system.<br><li><b>GRANT</b> - gives user's access privileges to database.</li></br><li><b>REVOKE</b> - withdraw access privileges given with the GRANT command.</li></br>",
            "sampleCode": "Create the new user using below query. \r\nExample: Username = KUMAR, Password = WELCOME.\r\n\r\nSQL> CREATE USER KUMAR IDENTIFIED BY WELCOME;\r\nUser created.\r\n\r\n\r\nGive the grand privileges to KUMAR user using below query.\r\n\r\nSQL> GRANT CONNECT, RESOURCE TO KUMAR;\r\nGrant succeeded.\r\n\r\n\r\nGive the grand privileges to KUMAR user for accessing an EMPLOYEES table using below query.\r\n\r\nSQL> GRANT SELECT ON EMPLOYEES TO KUMAR;\r\nGrant succeeded.\r\n\r\n\r\nRevert the EMPLOYEES table accessing privileges to KUMAR user using below query.\r\n\r\nSQL> REVOKE SELECT ON EMPLOYEES FROM KUMAR;\r\nRevoke succeeded.\r\n\r\n\r\nGive the grand privileges to PUBLIC user for accessing an EMPLOYEES table using below query.\r\n\r\nSQL> GRANT SELECT ON EMPLOYEES TO PUBLIC;\r\nGrant succeeded.\r\n\r\n\r\nRevert an EMPLOYEES table accessing privileges to PUBLIC user using below query.\r\n\r\nSQL> REVOKE SELECT ON EMPLOYEES FROM PUBLIC;\r\nRevoke succeeded.\r\n\r\n\r\nCreating an ROLE using below query.\r\n\r\nSQL> CREATE ROLE R1;\r\nRole created.\r\n\r\n\r\nGive the grand privileges to many users based on ROLE using below query.\r\n\r\nSQL> GRANT R1 TO KUMAR, VIC;\r\nGrant succeeded. \r\n\r\n\r\nGive the grand privileges to ROLE for accessing an EMPLOYEES table using below query.\r\nNote: Both the users (KUMAR, VIC) can able to access the EMPLOYEES table.\r\n\r\nSQL> GRANT SELECT ON EMPLOYEES TO R1;\r\nGrant succeeded.\r\n\r\n\r\nRevert an EMPLOYEES table accessing privileges to ROLE using below query.\r\n\r\nSQL> REVOKE SELECT ON EMPLOYEES FROM R1;\r\nRevoke succeeded.\r\n\r\n\r\nDeleting a ROLE using below query.\r\n\r\nSQL> DROP ROLE R1;\r\nRole dropped.",
            "outputImageURL": ""
        },
        {
            "title": "What is TCL?",
            "description": "<b>Transaction Control (TCL)</b> statements are used to manage the changes made by DML statements.<br><li><b>COMMIT</b> - It is used to save all pending changes.</li></br><li><b>ROLLBACK</b> - It is used to discard all pending changes.</li></br><li><b>SAVEPOINT</b> - identify a point in a transaction to which you can later roll back.</li><br>",
            "sampleCode": "Fetch all the records from students table using below query.\r\n\r\nSELECT * FROM STUDENTS;\r\n\r\n\r\nAdd three records into students table using below queries.\r\n\r\nINSERT INTO STUDENTS VALUES (10,'KUMAR');\r\nINSERT INTO STUDENTS VALUES (20,'ARUL');\r\nINSERT INTO STUDENTS VALUES (30,'ARUN');\r\n\r\n\r\nDo the permanent changes into students table using COMMIT.\r\nCOMMIT;\r\n\r\n\r\nAdd two records into students table using below queries.\r\n\r\nINSERT INTO STUDENTS VALUES (40,'SUJI');\r\nINSERT INTO STUDENTS VALUES (50,'HEMA');\r\n\r\n\r\nRevert the last two records insertion using ROLLBACK.\r\nROLLBACK;\r\n\r\n\r\nAdd two records into students table using below queries.\r\n\r\nINSERT INTO STUDENTS VALUES (60,'BABU');\r\nINSERT INTO STUDENTS VALUES (70,'VIJAYA');\r\n\r\n\r\nCrating a savepoint using below command.\r\nSAVEPOINT A;\r\n\r\n\r\nAdd two records into students table using below queries.\r\n\t\t\t\t\t\r\nINSERT INTO STUDENTS VALUES (80,'UMARANI');\r\nINSERT INTO STUDENTS VALUES (90,'MURUGAN');\r\n\r\n\r\nCrating a savepoint using below command.\r\nSAVEPOINT B;\r\n\r\n\r\nAdd two records into students table using below queries.\r\n\r\nINSERT INTO STUDENTS VALUES (100,'SRAVAN');\r\nINSERT INTO STUDENTS VALUES (110,'RAJKUMAR');\r\n\r\n\r\nRevert the savepoint using ROLLBACK.\r\nNote: If revert the savepoint means, all the records are reverted upto savepoint.\r\n\r\nROLLBACK TO B;",
            "outputImageURL": ""
        }
        ]
    },
    {
        "url": "constructor-and-types-in-java",
        "title": "constructor-and-types-in-java",
        "date": "Mar.17.2020",
        "pubish_by": "Purusothaman",
        "image": "article-java.png",
        "describe": [{
            "title": "Constructor and Types in Java",
            "description": "<b>Constructor</b> is a special member method that is used to initialize the object and its automatically called when object is created for a class.</br></br><b>Note: </b>Explicitly cannot invoke a constructor.</br></br><u><b>Rules of Constructor:</b></u><br><li>Constructor and class name should be same.</li><br><li>Constructor don’t have return type.</li><br><u><b>Types of Constructor:</b></u></br></br><li>Default Constructor</li><br><li>Parameterized Constructor</li><br><li>Copy Constructor</li>",
            "sampleCode": "",
            "outputImageURL": ""
        },
        {
            "title": "Default Constructor:",
            "description": "A <b>constructor that have no parameter</b> is known as default constructor.",
            "sampleCode": "class MyClass {\r\n\tMyClass() {\r\n\t\tSystem.out.println(\"This is Default Constructor\");\r\n\t}\r\n}",
            "outputImageURL": ""
        },
        {
            "title": "Parameterized Constructor:",
            "description": "A <b>constructor that have parameter</b> is known as Parameterized constructor.",
            "sampleCode": "class MyClass {\r\n\tMyClass(int a) {\r\n\t\tSystem.out.println(\"This is Parameterized Constructor” + a);\r\n\t}\r\n}",
            "outputImageURL": ""
        },
        {
            "title": "Copy Constructor:",
            "description": "A <b>constructor that have parameter type is object</b> is known as copy constructor.",
            "sampleCode": "class MyClass {\r\n\tMyClass(Sample y) {\r\n\t\tSystem.out.println(\"This is Copy Constructor\");\r\n\t\ty.m1();\r\n\t}\r\n}",
            "outputImageURL": ""
        },
        {
            "title": "Example:",
            "description": "Demo Program for constructor.",
            "sampleCode": "ConstructorDemo.java\r\n\r\nclass Sample {\r\n\tvoid m1() {\r\n\t\tSystem.out.println(\"This is m1()\");\r\n\t}\r\n}\r\n\r\nclass MyClass {\r\n\tMyClass() {\r\n\t\tSystem.out.println(\"This is Default Constructor\");\r\n\t}\r\n\r\n\tMyClass(int a) {\r\n\t\tSystem.out.println(\"This is Parameterized Constructor\" + a);\r\n\t}\r\n\r\n\tMyClass(Sample y) {\r\n\t\tSystem.out.println(\"This is Copy Constructor\");\r\n\t\ty.m1();\r\n\t}\r\n\r\n}\r\n\r\nclass ConstructorDemo {\r\n\tpublic static void main(String args[]) {\r\n\t\tnew MyClass();\r\n\t\tnew MyClass(4);\r\n\t\tSample x = new Sample();\r\n\t\tnew MyClass(x);\r\n\r\n\t}\r\n}",
            "outputImageURL": "constructordemo.png"
        },
        {
            "title": "Constructor Overloading:",
            "description": "Demo program for Constructor Overloading in java.",
            "sampleCode": "ConstructorOverLoadDemo.java\r\n\r\nclass MyClass {\r\n\r\n\tMyClass(int a) {\r\n\t\tSystem.out.println(\"The (int a) Constructor \" + a);\r\n\t}\r\n\r\n\tMyClass(int a, int b) {\r\n\t\tSystem.out.println(\"The (int a, int b) Constructor \" + (a+b));\r\n\t}\r\n\r\n}\r\n\r\nclass ConstructorOverLoadDemo {\r\n\tpublic static void main(String args[]) {\r\n\t\tnew MyClass(4);\r\n\t\tnew MyClass(4, 5);\r\n\t}\r\n}",
            "outputImageURL": "constructoroverloaddemo.png"
        }
        ]
    },
    {
        "url": "difference-between-string-stringbuffer-stringbuilder",
        "title": "difference-between-string-stringbuffer-stringbuilder",
        "date": "Mar.25.2020",
        "pubish_by": "Nanjundan",
        "image": "article-java.png",
        "describe": [{
            "title": "Difference b/w String, StringBuffer & StringBuilder",
            "description": "<b>What is String?</b></br></br>String is <b>immutable object</b>. It is stored into <b>Constant String Pool</b>. All immutable class (including String class) is thread safe in java. So <b>string cannot be used by two threads simultaneously.</b></br></br><b>Note: </b>Immutable(once created cannot be changed) means, Without creating new string object, we cannot modify the existing object content.",
            "sampleCode": "StringDemo.java\r\n\r\nclass StringDemo {\r\n\tpublic static void main(String args[]) {\r\n\t\tString s = \"Dev \";\r\n\t\tSystem.out.println(\"Before Concat Content: \" + s);\r\n\t\ts.concat(\"Technology Solutions\");\r\n\t\tSystem.out.println(\"After Concat Content: \" + s);\r\n\t\tString s1 = s.concat(\"Technology Solutions\");\r\n\t\tSystem.out.println(\"After Creating New Object: \" + s1);\r\n\t}\r\n}",
            "outputImageURL": "stringdemo.png"
        },
        {
            "title": "What is StringBuffer?",
            "description": "StringBuffer is <b>mutable</b> object. The object is stored in the <b>heap</b>. StringBuffer has the same methods as the StringBuilder, but <b>each method in StringBuffer is synchronized</b>, so that <b>StringBuffer is thread safe.</b></br></br><b>Note: </b>Mutable(can change the value of the object) means, Without new object creation, <b>we can modify the existing object content</b>.",
            "sampleCode": "StringBufferDemo.java\r\n\r\nclass StringBufferDemo {\r\n\t\r\n\t   public static void main(String args[]) {\r\n\t     StringBuffer sb = new StringBuffer(\"Dev \");\r\n\t     System.out.println(\"Before Append Content: \"+sb);\r\n\t     sb.append(\"Technology Solutions\");\r\n\t     System.out.println(\"After Append Content: \"+sb);\r\n\t   }\r\n}",
            "outputImageURL": "stringbufferdemo.png"
        },
        {
            "title": "What is StringBuilder?",
            "description": "StringBuffer is <b>mutable</b> object. The object is stored in the <b>heap</b>. StringBuilder <b>method is not synchronized</b>, so that <b>StringBuilderis not thread safe</b></br></br><b>Note:</b><b>StringBuilder is features of JDK1.5.</b> Mutable(can change the value of the object) means, Without new object creation, we can modify the existing object content.",
            "sampleCode": "StringBuilderDemo.java\r\n\r\nclass StringBuilderDemo {\r\n\r\n\tpublic static void main(String args[]) {\r\n\t\tStringBuilder sb = new StringBuilder(\"Dev \");\r\n\t\tSystem.out.println(\"Before Append Content: \" + sb);\r\n\t\tsb.append(\"Technology Solutions\");\r\n\t\tSystem.out.println(\"After Append Content: \" + sb);\r\n\t}\r\n}",
            "outputImageURL": "stringbuilderdemo.png"
        },
        {
            "title": "Difference between <b>String, StringBuffer</b> and <b>StringBuilder</b>",
            "description": "Compare of <b>String, StringBuffer</b> and <b>StringBuilder</b>",
            "sampleCode": "JavaClass\t\tStorage Area\t\t\tModifiable\t\tThread Safe\t\tPerformance\r\n---------\t\t------------\t\t\t-----------\t\t-----------\t\t-----------\r\n\r\nString\t\t\tConstant String Pool\t\tNo (immutable)\t\tYes\t\t\tFast\r\n\r\nStringBuffer\t\tHeap\t\t\t\tYes(mutable)\t\tYes\t\t\tVery slow\r\n\r\nStringBuilder\t\tHeap\t\t\t\tYes(mutable)\t\tNo\t\t\tFast",
            "outputImageURL": ""
        }
        ]
    }
]

export default ArticleDetails;